import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {BlogContainer} from "../../components/blog/container";
import {BlogMDWrapper} from "../../components/blog/md-wrapper";
import {CodeFragment} from "../../components/code-fragment";
import {MarkdownViewer} from "../../components/markdown-viewer";

const markdown = `
Described below are a set of opinionated processes and best practices about how to use Git - usually with Github or Gitlab - for software development. By no means is this an exhaustive list and each team and product will have a set of specific needs unique to them. Please take what you believe what will work well for you and leave what won’t.

The following recommendations are based on a general philosophy that all software development processes should be geared to allow teams to move fast and act autonomously. As a Javascript/React developer many of the guidelines and examples reference Web libraries, quick google searches should give you alternatives for other platforms. That said, let’s dive in.

### Relevant Roles
* Product Dev Lead: One engineer designated as lead responsible for the product as a whole.
* Engineer: Software Developers writing code for that product.
* Pull Request Submitter: The developer who opened a branch, made changes, and opened a pull request to have those changes merged back into the main branch.
* PR Primary Reviewer: When submitting a PR, the submitter designates one primary reviewer responsible for reviewing the code and signing off.
* PR Additional Reviewers: The submitter may also designate secondary reviewers, especially if there are certain aspects of the changes that are specialized, e.g. instrumentation changes and one team member is also specialized in that area and is not the primary reviewer. 
* QA Lead: One QA member responsible for the product as a whole.

## TLDR Process
1. An engineer is assigned to start a new task, typically in the form of a ticket.
2. Engineer branches off main following the established naming conventions to distinguish between features, bugs, prototypes, and hot fixes.
3. Engineer does their work on that branch related to that ticket following best practices around high-level and inline documentation, code styles, automated tests.
4. When ready for review, engineer opens a new pull request following the PR submission guidelines (see below). Submitter adds a description of the changes, instructions to test, and a video of the change working, as well as a primary and any necessary secondary reviewers.
5. Primary and secondary reviewers review the code as soon as possible to maintain team velocity, within 24 hours should be the goal. Conversations around changes should happen on the pull request.
6. If the change requires a manual QA review before being merged, the QA lead is tagged and asked to review on that branch. Most smaller changes should be able to be tested just among developers.
7. When all approvals are given, the Dev Lead merges the PR into the main branch, as long as the main branch is currently accepting PRs - there can be days where the main branch is locked for the next release candidate.
8. When the next product release train leaves the station, the change is live.

## Branch Management
How you manage branches for your product may vary, but generally a simpler/flatter structure is better. For most cases a single main branch with the latest stable version of the app should suffice, with engineers branching off that when beginning work on new tasks.

Some teams have a development branch in between to maintain with a main/master branch above to isolate changes in the release process - personally I think this can be managed as well with tags available in Github and Gitlab and keeps the whole project a bit simpler.

Engineers should create a new branch (off main) for each change. How you name these branches is somewhat up to you, however the following works well for us:
* Prefix of \`feat:\`, \`bug:\`, \`hotfix:\`, and \`prototype:\` based on the nature of the change
* For smaller features, bugs, and hot fixes, it’s generally a good idea to use the name of the ticket as the name -> e.g. \`bug:jira-4923\`
* For larger features (with many tickets), or prototypes a semantic name describing the change works well -> e.g. \`feat:internationalization\`

For larger features, major refactors, or breaking changes it’s generally a best practice to create one feature branch off main, then collaborate there with other developers in subbranches off the feature branch. 

As a general note, and certainly easier said than done, but changes on branches that result in pull requests should be kept as small as possible, and the submitter should do what they can to also make the code easy to review so that reviewers can give quick turnarounds for reviews.

> Best Practice: Your continuous integration tools should be setup to create a "snapshot" version of your entire app for QA or other engineers to reference if needed /for every PR in ready for review/. Rebuilds should happen with every change to the PR. This setup might require more work for iOS/Android native applications but is worth the setup time for the ease of QA in the future.

## Pull Requests
For a code change made on a branch to make it back to the main branch, it must go through a pull request process to ensure the quality of the code.

Each pull request must be reviewed by other engineers before being merged back, and as such this becomes a natural gate to ensure code quality and high standards across the code and documentation. 

Nevertheless, not all changes are made equally - some are large new features, some minor bug fixes, and maintaining velocity is still the goal.

Generally it seems PRs can be grouped into 3 categories based on their scope and review needs:
1. Small bug fixes and minor improvements: code changes are isolated and developers can easily verify a fix *without QA needing to get involved*.
2. Smaller changes with important user implications: Important UI changes, subtle animation fixes, or changes that could have performance implications across the relevant devices for your product that *do need QA to validate the improvement*.
3. Larger features, refactors, or breaking changes that need QA to validate changes and likely need their own branch with subbranches to make changes.

Pull requests should only be merged once all approvers have given their review, all review comments and suggestions are addressed, and the Dev Lead is able to merge the changes to main (when there is no code freeze).

> Engineers might also open a PR in a draft state before the work is ready for review, perhaps to get a live working link of the changes before reviewing or for making a prototype.

### Before you open a PR as a Submitter
You’re about to ask your teammates to take their time to review your code, so you want to make sure you give them everything they need.
* Check that you’ve added any high-level documentation to the Readme or wherever your team manages that information, as well as inline comments describing your functions, changes, and thought process.
* Ensure your following any code styles on naming conventions, database calls, and more based on your teams best practices.

### Opening a PR as the Submitter
What context and resources you want an engineer to add when opening the PR will need to be customized for your teams, but this should be a good start: 
* Instructions on how to test the changes you’ve made
* A loom or unlisted YouTube video showing the changes you’ve made being tested
* A link to the ticket (JIRA, Trello, etc.), the designs (figma, sketch), PRD (product requirements document)
* What changes are in scope for Pull Request, what can be improved in the future -> a best practice is creating an issue if that change won’t be implemented in that pull request.
* If this is a feature change with defined acceptance tests, those should be included as well
* Any other additional media or diagrams that will boost the understanding of those reviewing and looking back on this in the future.

The submitter chooses one main reviewer and additional reviewers based on needs (e.g. specializations) for the changes made in the PR.

Please copy/paste this snippet above if you find it helpful.

### Reviewing a PR
This will again need to be customized for your team, but this should be a generally good place to start:
- Does it work? Reviewer should run the code locally to ensure everything runs properly.
- General: Is all code complete and implemented? Is incomplete code flagged perhaps with TODO?
- Code Styles: Is the code clear and easy to understand? Are functions/variables logically named?
- Documentation: Do inline comments describe the changes and intent of the code? Are all functions commented?  Are edge-cases described? Are any custom calculations explained?
- Security: Is the change secure? What can go wrong? Are data inputs sanitized?
- Performance: Are there any performance implications to loading times? Could there be an impact on certain relevant devices? Are error logs added at sensitive and critical areas of the changes?
- Tests: Were unit tests written to cover these changes? (If applicable)
- Advanced: Are we abstracting code where possible? Are there better or simpler solutions?

If good suggestions do come up and a feature needs to be released, you may consider opening an issue or another ticket to capture items as future improvements for the change. 

At the end of the day, each reviewer should feel like they have a solid understanding of the change, it works, and enough documentation was added to ensure other team members could understand the change and make an urgent fix themselves if everyone else if offline.

When creating your own checklist keep in mind that a good checklist is an invaluable tool for helping reviewers move faster by reducing the need for them to remember all the context for a change. In the same breath, a bad checklist can promote nitpicking and slow down the development to a point where it hinders progress. Teams should strive to create a checklist free of redundancy and only include essentials.

> For code styles on the web, Airbnb has a great code style guide: [GitHub - airbnb/javascript: JavaScript Style Guide](https://github.com/airbnb/javascript)

### A note on Linters
Linters are an invaluable tool for teams to ensure their code quality standards are upheld by automatically finding - and sometimes fixing - inconsistencies across the project. [ESLint](https://eslint.org/) is a great tool on the web.

If you’re already using linters - great! If not, start simple with just a couple rules and add more as the team sees fit.

### Manual QA & Testing
These valuable resources can help uncover issues automated tests won’t find that might otherwise slip through and provide feedback to improve changes from another perspective. 

QA resources should not have to be brought in on every pull request as mentioned above - for larger features and smaller improvements with UX implications they should be brought in for sure. QA leads should look to add tests to their test suites where needed to increase coverage resulting from changes. 

As your team and product grow, the goal should be to increase automated test coverage while increasing the release cadence and adding rigor to reviews on pull requests to ensure code quality.

### Continuous Integration / Delivery (CI/CD)
Invest in having a robust set of tools to help you do your work better. A few areas to start would be:
* Upon opening a PR a new build of your app should be generated to test
* That build should update with each new commit to that PR
* Automated tests and reports as comments on the pull request that block merging until they all pass
* Bots to help remind the team about stale pull requests and providing reviews for pull requests
* Screenshots of the app working through an automated test solution

### Handling Hotfixes
Hotfixes may be an exception in terms of branch management, depending on how your release system is set up, if your releases are tied to tags, you may want to branch off that tag to apply the fix there directly to avoid having to merge all other changes to production just to fix on issue.

With more frequent releases, increased testing automation, and more rigid guidelines around code that does go out, the need for hotfixes should decline greatly over time.

### Automated Tests
Testing is a major and important topic and will have it’s own lengthy post, if your team already has an automated testing framework in place, great! You should add a requirement that pull requests for features and important bugs also need to add functional tests to capture that as part of the automated test suite for that product.

If not, adding one should be a top priority - start simple even if it’s just one test and invest first in getting the team up to speed on how to write and implement tests then integrate them step-by-step into your pull request process and your CI/CD pipelines.

Either way a review of the relevant test cases should be conducted with all team members to make sure all tests are still relevant and if any are missing - then these should be stack ranked by impact and time needed to complete which will give you a list of the most important tests to focus on when working on test automation for your product.

### Sources
* [Fog Creek Checklist](https://jesseheines.com/~heines/91.462/Resources/CodeReviewChecklists/StopMoreBugsWithOurCodeReviewChecklist_FogCreekBlog_2015-03-23.pdf)
* [Sourcelevel.io](https://sourcelevel.io/pull-requests-checklists-metrics-and-best-practices-a-definitive-guide)
* [Hackernoon](https://hackernoon.com/pull-request-checklist-what-you-need-to-do-before-assigning-a-pr-to-someone-x4263uuk)
* [PullRequest.com](https://www.pullrequest.com/blog/what-belongs-in-an-effective-code-review-checklist/)
`;

export default function BlogPragmaticPullRequests() {


    return (
        <Page menu>
            <Header subtitle="Tools" title='A Pragmatic Approach to Pull Requests & Git'
            />
            <BlogContainer>
                <BlogMDWrapper>
                    <MarkdownViewer source={markdown}></MarkdownViewer>
                </BlogMDWrapper>
            </BlogContainer>
        </Page>
    )
};